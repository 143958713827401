import { ReactElement, ReactNode } from "react"
import { IconType } from "react-icons"
import { cn } from "utils/cn"

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: ReactNode | ReactElement
  loading?: boolean
  className?: string
  prefixIcon?: string
  PreIcon?: IconType
  PreIconClassName?: string
  suffixIcon?: string
  tab?: string | number
  tabClass?: string
  prefixIconClass?: string
  suffixIconClass?: string
  authSuffixBtnIcon?: boolean
}

export const Button = ({
  text,
  loading,
  className,
  prefixIcon,
  suffixIcon,
  tab,
  tabClass,
  prefixIconClass,
  suffixIconClass,
  PreIcon,
  PreIconClassName,
  authSuffixBtnIcon = true,
  ...rest
}: IButtonProps) => {
  return (
    <button
      {...rest}
      className={`button capitalize opacity-100 hover:opacity-70 flex items-center gap-2 ${className} box-border disabled:cursor-not-allowed disabled:bg-opacity-40`}
    >
      {prefixIcon && (
        <img
          src={prefixIcon}
          alt=""
          className={`h-[0.8rem] md:h-auto ${prefixIconClass}`}
        />
      )}
      {PreIcon && <PreIcon className={PreIconClassName} />}
      {tab && (
        <span
          className={`flex items-center justify-center rounded-[50%] md:rounded-[2.187rem] py-[0.15rem] px-[0.3rem] md:px-[0.5rem] text-[0.4rem] md:text-[0.687rem] font-normal leading-normal ${tabClass}`}
        >
          {tab}
        </span>
      )}
      {loading ? "Loading..." : text}

      {suffixIcon && (
        <img
          src={suffixIcon}
          alt=""
          className={cn(
            "h-[0.8rem] md:h-auto",
            {
              "auth-btn-icon": authSuffixBtnIcon,
            },
            suffixIconClass,
          )}
        />
      )}
    </button>
  )
}
