import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { _getToken } from 'utils';
import { toast } from 'react-toastify';
import { agility_fe_dev_base_url } from 'utils/environments';

interface IPostProps {
  url: string;
  payload?: object;
}
interface MyErrorResponse {
  message: string;
  // other fields
}

const apiResource = () => {
  // console.log("DEV envirenment=== ", process.env.REACT_APP_AGILITY_DEV_BASE_URL)
  // const baseURL = process.env.REACT_APP_AGILITY_DEV_BASE_URL;


  const service = axios.create({
    baseURL: `${agility_fe_dev_base_url}/`,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
  });

  service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = _getToken();

    if (!token) return config;
    config.headers!['access-token'] = token;
    return config;
  });

  service.interceptors.response.use(
    (response: AxiosResponse) => {
      return response?.data;
    },
    (error: AxiosError) => {
      const errorData = error?.response?.data as MyErrorResponse;
      if (error?.response === undefined) {
        // showToast("No internet connection", "error");
        toast('No internet connection', {
          type: 'error',
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
        return false;
      } else {
        const status = error?.response?.status;
        // const errors = error?.response?.data;

        if (status === 404) {
          // return error
          // showToast("Resource not found", "error");
          // toast('Resource not found', {
          //   position: 'top-right',
          //   autoClose: 5000,
          // });
        }
        if (status === 400 || status === 401) {
          // showToast("Resource not found", "error");
          toast(`${errorData.message}`, {
            type: 'error',
            position: 'top-right',
            autoClose: 5000,
            theme: 'colored',
          });
        }
        return errorData
      }
    },
  );

  return {
    get: async (url: string) => {
      try {
        const data = service.get(url);
        const resolvedData = await Promise.resolve(data);
        return resolvedData;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    post: async ({ url, payload }: IPostProps) => {
      try {
        const data = service.post(url, payload);
        const resolvedData = await Promise.resolve(data);
        return resolvedData;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    patch: async ({ url, payload }: IPostProps) => {
      try {
        const data = service.patch(url, payload);
        const resolvedData = await Promise.resolve(data);
        return resolvedData;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    delete: async ({ url, payload }: IPostProps) => {
      try {
        const data = service.delete(url, payload);
        const resolvedData = await Promise.resolve(data);
        return resolvedData;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    put: async ({ url, payload }: IPostProps) => {
      try {
        const data = service.put(url, payload);
        const resolvedData = await Promise.resolve(data);
        return resolvedData;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
};

export const apiService = apiResource();
