
import { useQuery } from '@tanstack/react-query';
import { getProfileApi } from 'apiRequest/auth/authenticationRequest';
import { Button } from 'components/button/Button';
import { IButtonDetails } from 'components/header/Header';
import { Loader } from 'components/modal/loader/Loader';
import { Navbar } from 'components/nav/Navbar';
import { Sidebar } from 'components/sidebar'
import { CaptureShipmentContext } from 'context/shipment/CaptureShipmentContext';
import { IUtilContextProps, UtilContext } from 'context/utils/UtilContext';
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { _isUserLoggedIn } from 'utils';
import { ICaptureShipmentContextProps } from 'utils/interfaces';

type PropsWithChildren<P = unknown> = P & { children: ReactNode };


interface IMobileNavProps {
  headerNaviagtionBtn?: IButtonDetails[]
  amount?: string | number
  className?: string
}


export const Layout = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  const navigate = useNavigate()

  const [sidebar] = useState(true)

  useEffect(() => {
    if (!_isUserLoggedIn()) {
      toast("Login Required", {
        type: "error",
        position: "top-right",
        theme: "colored",
        autoClose: 6000,
      })
      navigate("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useQuery({
    queryKey: ["get-profile"],
    queryFn: async () => await getProfileApi(),
    enabled: !!(_isUserLoggedIn() === true),
    retry: false,
  })

  const { loader, setLoader } = useContext(UtilContext) as IUtilContextProps
  const { setClearItem, setClearReceiver, multiShipment,
  }
    = useContext(CaptureShipmentContext) as ICaptureShipmentContextProps

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
      setClearItem(false)
      setClearReceiver(false)
    }, 1000)
  }, [loader, setLoader, multiShipment])

  return (
    <div className='w-screen h-screen relative flex overflow-hidden box-border'>
      {_isUserLoggedIn() &&
        <>
          <aside className={`hidden tb:block md:min-w-[14rem] md:w-[14rem] md:max-w-[16rem] h-full bg-col_141414 relative overflow-y-hidden`}>
            <Sidebar sidebar={sidebar} className="" />
          </aside>
          <main className='content-main relative h-full w-full overflow-x-auto bg-col_f8f8f8 box-border'>
            <Navbar />
            <div className='w-full h-auto'>
              {children}
            </div>
          </main>
        </>}
      {loader ? <Loader /> : null}
    </div>
  )
}

export const MobileNav = ({ amount, headerNaviagtionBtn, className }: IMobileNavProps) => {
  return (
    <div className={`w-full h-[8vh] flex items-center justify-between fixed bottom-0 tb:hidden py-3 pl-4 pr-4 shadow-header_shadow bg-col_fff box-border ${className}`}>
      <p className="amount text-col_219653 text-[1rem] font-bold">{amount}</p>
      {headerNaviagtionBtn &&
        <div className="flex items-center gap-2 md:hidden ">
          {headerNaviagtionBtn.map((btn: any, i: number) => (
            <Button
              key={i}
              text={btn.text}
              prefixIcon={btn.prefixIcon}
              suffixIcon={btn.suffixIcon}
              onClick={() => btn.onClick && btn.onClick()}
              className={`btn-default py-2 px-2 cursor-pointer text-[0.75rem]
                ${btn.type === "white-btn" && "bg-col_ececec text-col_807e7c"}
                ${btn.type === "red-btn" && "bg-col_e84e40 text-col_fff"}
              `}
            />
          ))}
        </div>
      }
    </div>
  )
}

export const MobileAmountCont = () => {
  return (
    <p></p>
  )
}
