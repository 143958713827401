

import { lazy } from 'react';

export const Pages = {
    Dashboard:  lazy(() =>
        import('pages/dashboard/Dashboard').then((module) => ({
          default: module.Dashboard
    }))),

    CaptureShipments: lazy(() =>
        import('pages/shipments/capture-shipments/CaptureShipments').then((module) => ({
          default: module.CaptureShipments
    }))),

    Notifications: lazy(() =>
      import('pages/Notifications').then((module) => ({
        default: module.Notifications
    }))),
    DailySales: lazy(() =>
      import('pages/shipments/daily-sales/DailySales').then((module) => ({
        default: module.DailySales
  }))),

  shipmentInvoice:lazy(()=>
  import('pages/shipments/daily-sales/shipment-invoice/ShipmentInvoiceDetail').then((module)=>({
    default:module.ShipmentInvoiceDetail
  }))
  ),

    PaymentOptions: lazy(() =>
      import('pages/shipments/capture-shipments/PaymentOptions').then((module) => ({
        default: module.PaymentOptions
      }))
    ),
    CreateReturnShipment:lazy(()=>
    import('pages/shipments/return-shipment/CreateReturnShipment').then((module)=>({
      default:module.CreateReturnShipment
    }))
    ),

     InvoiceCaptureShipment:lazy(()=>
    import('pages/shipments/capture-shipments/InvoiceCaptureShipment').then((module)=>({
      default:module.InvoiceCaptureShipment
    }))),




    BankProcessingOrder:lazy (()=>
    
    import('pages/shipments/bank-processing-order/BankProcessingOrder').then((module)=>({
      default:module.BankProcessingOrder
    })) 
    
    )
  

}
